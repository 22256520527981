import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/UpdateLayout.js";
import List from '../../../components/LinkCard/List';
import ListItem from '../../../components/LinkCard/ListItem';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "more-components",
      "style": {
        "position": "relative"
      }
    }}>{`More components`}</h2>
    <p>{`We're almost at the end of our baseline components, and this release adds
another set of useful components for you to use.`}</p>
    <List mb={8} items={[{
      title: 'Accordion',
      to: `/components/accordion/code`
    }, {
      title: 'AutoComplete',
      to: `/components/autocomplete/code`
    }]} mdxType="List" />
    <h2 {...{
      "id": "marketing-colors",
      "style": {
        "position": "relative"
      }
    }}>{`Marketing colors`}</h2>
    <p>{`Some brands requested an extra color to be added to their palette, which they
can use for marketing purposes. As such, we have added the Marketing Colors
category. Currently, there is only one brand (Het Nieuwsblad) that has a defined
marketing color, all other brands fall back on one of their other brand colors.`}</p>
    <List my={8} mdxType="List">
  <ListItem title="Colors" to="/foundations/color" mdxType="ListItem" />
    </List>
    <List mb={8} mdxType="List">
  <ListItem title="v0.8.0 changelog" description="Get a full overview of what's changed in the changelog" to="/updates/changelog" mdxType="ListItem" />
    </List>
    <h2 {...{
      "id": "looking-forward",
      "style": {
        "position": "relative"
      }
    }}>{`Looking forward`}</h2>
    <p>{`Every release, we'll try to give you an insight on our plans for the next
release. That way you know what to expect, and see how you can adapt to our new
features.`}</p>
    <p>{`We are very close to finishing the base set of building blocks. Next release
should round out the last few missing components! One of which will be the Text
component, to more easily resonate your implementation with how a designer
designed their visuals.`}</p>
    <p>{`When the base set of building blocks is complete, we'll start looking toward
releasing our first really stable release, denoted by the 1.0.0 version. This
will include some breaking changes (e.g. deprecated tokens). As with all
breaking changes, we'll always try to indicate it as deprecated for a while,
after which it will be effectively removed in the next major release.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      